.pricing-table-container {
  /* perspective: 1000px; */
  margin: 50px auto;
}

.pricing-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform-style: preserve-3d;
}

th, td {
  padding: 15px;
  text-align: center;
  background-color: #f4f4f4;
}

th {
  background-color: #3498db;
  color: white;
}

tr:nth-child(even) {
  background-color: #ecf0f1;
}
