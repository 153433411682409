@media only screen and (min-width: 501px) {
    #table-stats {
        width: 29%;
    }

    #table-main-small {
        display: none
    }

    .search {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        /* width: 200px; */
        text-align: left;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        /* width: 200px; */
    }
}

@media only screen and (min-width: 768px) {
    .search {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        /* width: 200px; */
        text-align: left;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        /* width: 200px; */
    }
}

@media only screen and (min-width: 1000px) {
    .search {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        /* width: 240px; */
        text-align: left;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        /* width: 240px; */
    }
}

@media only screen and (min-width: 1100px) {
    .search {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        /* width: 270px; */
        text-align: left;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        /* width: 270px; */
    }
}

@media only screen and (min-width: 1304px) {
    .search {
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }

    .subDiv {
        height: 100px
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        /* width: 340px; */
        text-align: left;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        /* width: 340px; */
        text-align: left;
    }
}

.subDiv {
    display: flex;
    justify-content: space-around;
}

#table-stats {
    line-height: 0px;
    text-align: center;
}

#table-main {
    text-align: center;
}

#table-main-small {
    text-align: center;
}

#mobile-style {
    font-size: 13px;
    padding: 0px 5px 0 5px;
    margin: 0px;
    text-align: left;
    vertical-align: middle;
    line-height: 15px;
    border: none
}

@media only screen and (max-width: 500px) {
    #table-stats {
        width: 100%;
    }

    #table-main {
        display: none
    }

    .search {
        display: block;
        margin-bottom: 10px;
        border: 1px solid lightgrey;
        padding: 10px;
        height: 110px;
        border-radius: 5px;
        text-align: left;
    }

    .subDiv {
        display: block;
    }

    .setting {
        display: block;
        margin-bottom: 30px;
        text-align: left;
    }

    .mainsearchcontainer {
        text-align: left;
        display: block;
        margin-bottom: 15px;
    }
}

#details {
    /* display: inline-block; */
    /* margin-right: 20px; */
    line-height: 0;
    font-size: 14px;
}

.page {
    padding: 30px;
}