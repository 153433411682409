.guide-panel {
    max-width: 1000px;
    width: 800px;
    margin: 0 auto;
    padding: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .code-input {
    margin-bottom: 20px;
  }
  
  textarea {
    width: 100%;
    height: 500px;
    padding: 10px;
  }
  
  .guide {
    margin-top: 20px;
  }
  
  h2 {
    color: #3498db;
  }
  