@media only screen and (max-width: 500px) {
    .loginDiv {
        width: 85%;
        padding: 15px;
    }
}

@media only screen and (min-width: 501px) {
    .loginDiv {
        width: 70%;
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .loginDiv {
        width: 65%;
        padding: 20px;
    }
}

@media only screen and (min-width: 1000px) {
    .loginDiv {
        width: 50%;
        padding: 30px;
    }
}

@media only screen and (min-width: 1304px) {
    .loginDiv {
        width: 40%;
        padding: 30px;
    }
}